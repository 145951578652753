<template>
  <div>
    <b-jumbotron
      header="Goslar Service App"
      lead="Orte des Landkreises Goslar"
      header-level="5"
      class="p-4"
    >
      <b-button variant="primary" to="/places">{{
        $t("app.menu.places")
      }}</b-button>
      <hr class="my-4" />

      <p class="text-danger font-weight-bold">
        Die Goslar Service App wird zu Ende 2024 eingestellt.
      </p>
      <p>
        Die App umfasst die Orte des Landkreises Goslar: Goslar, Bad Harzburg,
        Braunlage, Clausthal-Zellerfeld, Langelsheim, Liebenburg und Seesen.
      </p>

      <p>Features:</p>
      <ul>
        <li>
          <strong>News:</strong> Hier werden aktuelle Meldungen aus offiziellen
          Kanälen wie Landkreis, Stadt, Polizei und Feuerwehr angezeigt.
        </li>
        <li>
          <strong>Müllabfuhr:</strong> Nachdem du deinen Ort und deine Straße
          ausgewählt hast, werden dir die Abfuhrtermine der
          Kreiswirtschaftsbetriebe angezeigt. Optional können Push-Mitteilungen
          für die Termine aktiviert werden.
        </li>
        <li>
          <strong>Wetterwarnungen:</strong> Es werden Warnungen vom deutschen
          Wetterdienst für die gewählten Orte angezeigt.
        </li>
        <li>
          <strong>Profil:</strong> Optional kannst du dich registrieren, um
          deine Daten auf mehreren Plattformen zu nutzen.
        </li>
      </ul>
    </b-jumbotron>
  </div>
</template>

<script>
export default {};
</script>
